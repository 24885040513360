import React from 'react';
import SemDados from '../SemDados';

import '../styles.scss';

const MaterialInvestimento = (props) => {
  const { data, dataLabels } = props;
  const titulo = 'INVESTIMENTO FARMAX MÊS A MÊS';

  if (!data || data.length < 1) {
    return <SemDados titulo={titulo} />;
  }

  const formatarValor = (valor) => {
    return `R$${valor.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
    })}`;
  };

  // mapeia os materiais e retorna o custo por material, se não houver retorna 0
  const getMateriais = (materiais, mesString) => {
    const tmpMateriais = materiais.map((item) => {
      return item.nome;
    });

    return dataLabels.map((label) => {
      const index = tmpMateriais.indexOf(label);
      if (index > -1) {
        return (
          <td key={`i-${label}-${mesString}`}>
            {formatarValor(materiais[index].totalCusto)}
          </td>
        );
      }
      return <td key={`i-${label}-${mesString}`}>-</td>;
    });
  };

  return (
    <div className="grafico" style={{ overflow: 'hidden' }}>
      <div className="grafico-titulo">
        <span>{titulo}</span>
      </div>
      <div className="tabela-container">
        <table className="tabela">
          <thead>
            <tr key="rh">
              <th
                className="fixed-header fixed-col-1 fixed-index"
                style={{ width: '100px' }}
                aria-label="header"
                key="ha"
              >
                Ano de Data
              </th>
              <th
                className="fixed-header fixed-col-2 fixed-index"
                style={{ width: '100px' }}
                aria-label="header"
                key="hm"
              >
                Mês de Data
              </th>
              {/* <th
                className="fixed-header fixed-col-3 fixed-index"
                style={{ width: '100px' }}
                aria-label="header"
                key="hst"
              >
                Soma de Total Verba
              </th> */}
              {dataLabels.map((item) => {
                return (
                  <th
                    className="fixed-header"
                    style={{ width: '100px' }}
                    aria-label="header"
                    key={`h-${item}`}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data.map((ano) => {
              return ano.data.map((mes, index) => {
                const mesString = `r${ano.ano}-${mes.nome}`;
                return (
                  <tr key={`r${mesString}`}>
                    {index === 0 ? (
                      <td
                        className="fixed-col-1"
                        key={`ia-${mesString}`}
                        rowSpan={ano.data.length}
                      >
                        {ano.ano}
                      </td>
                    ) : null}
                    <td className="fixed-col-2" key={`im-${mesString}`}>
                      {mes.nome}
                    </td>
                    {/* <td className="fixed-col-3" key={`ist-${mesString}`}>
                      {formatarValor(mes.totalVerba)}
                    </td> */}
                    {getMateriais(mes.material, mesString)}
                  </tr>
                );
              });
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MaterialInvestimento;
