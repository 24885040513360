import React from 'react';

import './styles.scss';
// import BrandWhite from 'assets/imgs/brf_branco.png';
import BrandWhite from 'assets/imgs/logo_farmax.png';

const Brand = (props) => {
  const { white } = props;
  return <img className="brand" src={white ? BrandWhite : ''} alt="Brf" />;
};

export default Brand;
