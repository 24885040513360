/* eslint-disable */
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faFileAlt,
  faSearch,
  faImage,
  faFileImage,
  faAt,
  faUser,
  faSignOutAlt,
  faBell,
  faChartLine,
} from '@fortawesome/free-solid-svg-icons';

import { Context } from 'context/AuthContext';
import Brand from 'components/_base/Brand';

import artesProntas from 'assets/imgs/icons/icon_artesprontas_gray.svg';
import campanha from 'assets/imgs/icons/icon_campanha_gray.svg';
import materiais from 'assets/imgs/icons/icon_materiais_gray.svg';
import histpedidos from 'assets/imgs/icons/icon_histpedidos_gray.svg';

import './styles.scss';

const NavigationMenu = () => {
  const { handleLogout } = useContext(Context);
  const userAuth = JSON.parse(localStorage.getItem('midias_farmax'));

  return (
    <div className="menu">
      <div className="header-menu">
        <div>
          <Brand white />
          <p className="mt-3">Bem Vindo</p>
          <h3>{userAuth && userAuth.nome}</h3>
        </div>
      </div>
      {(userAuth && userAuth.acessaApenasBancoMidias) === true ?
        <div className="body-menu">
          <ul>
            <li>
              <Link to="/">
                <FontAwesomeIcon icon={faHome} />
                <span>Home</span>
              </Link>
            </li>
            <li>
              <Link to="/banco-de-imagens">
                <FontAwesomeIcon icon={faImage} />
                <span>Banco de Mídias</span>
              </Link>
            </li>
            <li>
              <Link to="/atendimento">
                <FontAwesomeIcon icon={faAt} />
                <span>Atendimento</span>
              </Link>
            </li>
            <li>
              <Link to="/meus-dados">
                <FontAwesomeIcon icon={faUser} />
                <span>Meus Dados</span>
              </Link>
            </li>
          </ul>
          <div className="logout-button">
            <Button variant="primary" onClick={handleLogout}>
              <FontAwesomeIcon icon={faSignOutAlt} />
              <span>Sair</span>
            </Button>
          </div>
        </div>
        :
        <div className="body-menu">
          <ul>
            <li>
              <Link to="/">
                <FontAwesomeIcon icon={faHome} />
                <span>Home</span>
              </Link>
            </li>
            <li>
              <Link to="/pedido-materiais">
                <img src={materiais} alt="material" />
                <span>Materiais Personalizados</span>
              </Link>
            </li>
            {/* <li>
              <Link to="/pedido-campanha">
                <img src={campanha} alt="material" />
                <span>Campanhas</span>
              </Link>
            </li> */}
            {/* <li>
              <Link to="/artes-prontas">
                <img src={artesProntas} alt="artes prontas" />
                <span>Artes Prontas</span>
              </Link>
            </li> */}
            <li>
              <Link to="/rascunho">
                <FontAwesomeIcon icon={faFileAlt} />
                <span>Rascunhos</span>
              </Link>
            </li>
            <li>
              <Link to="/historico-pedidos">
                <img src={histpedidos} alt="historico pedidos" />
                <span>Histórico de Pedidos</span>
              </Link>
            </li>

            <li>
              <Link to="/pendencias">
                <FontAwesomeIcon icon={faBell} />
                <span>Pendências</span>
              </Link>
            </li>
            <li>
              <Link to="/consulta">
                <FontAwesomeIcon icon={faSearch} />
                <span>Consulta</span>
              </Link>
            </li>
            <li>
              <Link to="/banco-de-imagens">
                <FontAwesomeIcon icon={faImage} />
                <span>Banco de Mídias</span>
              </Link>
            </li>
            <li>
              <Link to="/banco-de-artes">
                <FontAwesomeIcon icon={faFileImage} />
                <span>Banco de Artes</span>
              </Link>
            </li>
            <li>
            <Link to="/relatorios">
              <FontAwesomeIcon icon={faChartLine} />
              <span>Relatórios</span>
            </Link>
          </li>
            <li>
              <Link to="/atendimento">
                <FontAwesomeIcon icon={faAt} />
                <span>Atendimento</span>
              </Link>
            </li>
            <li>
              <Link to="/meus-dados">
                <FontAwesomeIcon icon={faUser} />
                <span>Meus Dados</span>
              </Link>
            </li>
          </ul>
          <div className="logout-button">
            <Button variant="primary" onClick={handleLogout}>
              <FontAwesomeIcon icon={faSignOutAlt} />
              <span>Sair</span>
            </Button>
          </div>
        </div>}
    </div>
  );
};

export default NavigationMenu;
