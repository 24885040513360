import React from 'react';
import { Button } from 'react-bootstrap';
import logo from 'assets/imgs/logo_farmax.png';

import './styles.scss';

const TabHeader = (props) => {
  const { title, handleExportClick, parentRef, parentKey } = props;
  return (
    <div className="tab-header">
      <div className="header-content">
        <img src={logo} alt="logo" className="img-logo" />
        <h1 className="titulo-header">{title.toUpperCase()}</h1>
        <Button
          className="btn-exportar"
          onClick={() => {
            handleExportClick(parentRef, parentKey);
          }}
        >
          Exportar página
        </Button>
      </div>
      <div className="gradient" />
    </div>
  );
};

export default TabHeader;
