/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable import/order */
import React, { useState, useContext } from 'react';
import { getStringFromDate } from '../_constants';
import { Link } from 'react-router-dom';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, Card } from 'react-bootstrap';

import { Context } from 'context/AuthContext';
import './styles.scss';
import ImgFluxo from 'assets/imgs/fluxo-arte-facil.png';

function Sucesso(props) {
  let { model, enviarSolicitacao } = props;
  const { showLoader } = useContext(Context);
  const [ico, setIcon] = useState(false);

  const mudarIcon = () => {
    if (ico === false) {
      setIcon(true);
    } else {
      setIcon(false);
    }
  };

  const resumoItem = (item, model) => {
    return (
      <div className="col-lg-12 resumo mb-4" key={item.materialComunicacaoId}>
        <div className="resumo-header">
          <Accordion.Toggle
            onClick={mudarIcon}
            as={Card.Header}
            eventKey={item.materialComunicacaoId}
          >
            {item.pedidoId} - {item.materialComunicacao.nome}
            <div className="float-right">
              <FontAwesomeIcon
                id="icone"
                icon={ico !== true ? faAngleDown : faAngleUp}
              ></FontAwesomeIcon>
            </div>
          </Accordion.Toggle>
        </div>
        <Accordion.Collapse eventKey={item.materialComunicacaoId}>
          <div className="resumo-body">
            <div className="resumo-informacoes">
              <h4>Informações</h4>
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <th>Solicitante</th>
                    <td>{model?.solicitante?.nome}</td>
                  </tr>
                  <tr>
                    <th>Supervisor</th>
                    <td>{model?.usuarioSuperior?.nome}</td>
                  </tr>
                  <tr>
                    <th>Regional</th>
                    <td>{model.loja?.regional?.nome}</td>
                  </tr>
                  <tr>
                    <th>Cliente</th>
                    <td>{model.loja?.razaoSocial}</td>
                  </tr>
                  <tr>
                    <th>CNPJ</th>
                    <td>{model.loja?.cnpj}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="resumo-informacoes">
              <h4>Layout</h4>
              <img
                src={model.solicitacoes[0].materialComunicacao.urlImagem}
                className="img-fluid"
              />
              <hr />
            </div>
            <div className="resumo-informacoes">
              <h4>Ação Promocional</h4>
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <th>Início da Vigência</th>
                    <td>
                      {getStringFromDate(
                        item.solicitacaoDados?.dataVeiculacaoInicio
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Fim da Vigência</th>
                    <td>
                      {getStringFromDate(
                        item?.solicitacaoDados?.dataVeiculacaoFim
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Incluir logo?</th>
                    <td>{item.solicitacaoDados?.possuiLogo ? 'Sim' : 'Não'}</td>
                  </tr>
                  <tr>
                    <th>Tamanhos</th>
                    <td>
                      {item.solicitacaoTamanhos.map(
                        (tamanho) => `${tamanho.largura}x${tamanho.altura} `
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Observação</th>
                    <td>{item.solicitacaoDados?.observacoesGerais}</td>
                  </tr>
                  <tr>
                    <th>Incluir Ação Promocional?</th>
                    <td>
                      {item.solicitacaoDados.possuiAcaoPromocional
                        ? 'Sim'
                        : 'Não'}
                    </td>
                  </tr>
                  {item.solicitacaoDados.possuiAcaoPromocional &&
                    item.solicitacaoAcaoPromocionais.length &&
                    item.solicitacaoAcaoPromocionais.map((acao, key) => (
                      <tr key={key}>
                        <th></th>
                        <td>
                          {acao.acaoPromocional.texto}
                          {'  '}
                          {acao.solicitacaoAcaoPromocionalValores.length
                            ? acao.solicitacaoAcaoPromocionalValores.map(
                                (valor) =>
                                  `${valor.acaoPromocionalCampo.texto}: ${
                                    valor.valor ? valor.valor : ''
                                  } `
                              )
                            : ''}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="resumo-informacoes">
              <h4>Dados de Envio</h4>
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <th>Nome</th>
                    <td>{item.solicitacaoEntregaDigital?.nomeResponsavel}</td>
                  </tr>
                  <tr>
                    <th>Telefone</th>
                    <td>
                      {item.solicitacaoEntregaDigital?.telefoneResponsavel}
                    </td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{item.solicitacaoEntregaDigital?.emailResponsavel}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Accordion.Collapse>
      </div>
    );
  };

  return (
    <div className="container mt-5 content-pedido-resumo">
      <div className="row mb-4">
        <div className="col-lg-12">
          <h4 className="text-center mb-5">
            Suas solicitações foram enviadas com sucesso! <br />
            Agora elas entrarão no fluxo de aprovação da plataforma, conforme a
            seguir:
          </h4>
          <img className="d-block w-100" src={ImgFluxo} alt="fluxo" />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <Accordion>
            {model.solicitacoes &&
              model.solicitacoes.map((item) => resumoItem(item, model))}
          </Accordion>
        </div>
      </div>

      <div className="row">
        <div className="col-12 d-flex flex-row-reverse">
          <Link to="/">
            <button
              type="button"
              className="btn btn-primary btn-navigation btn-navigation-right"
            >
              <span>Pagina Inicial</span>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Sucesso;
