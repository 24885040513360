/* eslint-disable */
import React, { useState, useContext } from 'react';
import { Table, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faCheckCircle,
  faDownload,
  faTimes,
  faMinus,
  faEye,
  faPaperPlane,
} from '@fortawesome/free-solid-svg-icons';

import { Context } from 'context/AuthContext';
import { GET_IMAGE_ZIP } from 'services/Download';

import './card-item.scss';
import { GET_ENVIAR_EMAIL } from 'services/BancoImagem';
import axios from 'axios';

const CardItem = ({ produto, selecionado, handler }) => {
  const [showModal, setShowModal] = useState(false);
  const { showLoader } = useContext(Context);

  const [show, setShow] = useState(false);
  const [email, setEmail] = useState('');

  const [progress, setProgress] = useState({ status: '', percentage: '' });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const downloadImagem = async (id) => {
  //   const ids = [id];
  //   showLoader(true);

  //   const data = await GET_IMAGE_ZIP(ids);

  //   const url = window.URL.createObjectURL(new Blob([data]));
  //   const link = document.createElement('a');
  //   link.href = url;
  //   link.setAttribute('download', 'imagens.zip');
  //   document.body.appendChild(link);
  //   link.click();
  //   showLoader(false);
  // };

  const downloadImagem = async (img) => {
    const documentStyles = document.documentElement.style;
    let progressPercentage = 0;

    progress.status = 'in-progress';
    setProgress({ ...progress });

    axios({
      url: img.urlImagem,
      method: 'GET',
      responseType: 'blob',
      onDownloadProgress(progressEvent) {
        progressPercentage = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );

        progress.percentage = progressPercentage;

        documentStyles.setProperty('--progress', `${progressPercentage}%`);

        setProgress({ ...progress });
      },
    }).then((response) => {
      progress.status = 'finished';
      setProgress({ ...progress });

      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      let filename = img.urlImagem.substring(
        img.urlImagem.lastIndexOf('/') + 1
      );
      filename = filename.split('?')[0];
      link.setAttribute('download', filename); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  };

  const sendEmailDownload = async (produto) => {
    showLoader(true);
    const sucess = await GET_ENVIAR_EMAIL(produto, email);
    showLoader(false);
    if (Boolean(sucess)) {
      setShow(false);
    }
  };

  return (
    <>
      <div className="card" key={produto.bancoImagemId}>
        <div
          className="card-title"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              whiteSpace: 'nowrap',
              width: '12em',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {' '}
            {produto.descricao}{' '}
          </span>
        </div>

        <div className="img-card">
          <span
            style={{
              display: selecionado ? 'block' : 'none',
            }}
          >
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{ color: '#28B83B', fontSize: '1.8rem' }}
            />
          </span>
          <img
            className="card-img-top center-block"
            src={produto.urlImagemMiniatura}
            alt="imagem"
          />
        </div>
        <div className="card-body">
          <div className="row justify-content-md-center">
            <a
              onClick={() => {
                handleShow();
              }}
              className="btn btn-primary "
            >
              <FontAwesomeIcon icon={faPaperPlane} />
            </a>
            <a
              onClick={() => {
                setShowModal(true);
              }}
              className="btn btn-primary "
            >
              <FontAwesomeIcon icon={faEye} />
            </a>
            <a
              onClick={(event) => {
                event.stopPropagation('click');
                handler(produto);
              }}
              value="Ativo"
              id="add"
              className="btn btn-primary"
            >
              <FontAwesomeIcon icon={selecionado ? faMinus : faPlus} />
            </a>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Insira o e-mail para receber os links de download.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label>E-mail</label>
            <input
              type="text"
              className="form-control"
              value={email || ''}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => sendEmailDownload(produto.bancoImagemId)}
          >
            ENVIAR
          </Button>
        </Modal.Footer>
      </Modal>
      {showModal ? (
        <div
          onClick={() => {
            setShowModal(false);
          }}
          className="mb-modal"
        >
          <div
            onClick={(event) => {
              event.stopPropagation('click');
            }}
            className="mb-modal-body"
          >
            <FontAwesomeIcon
              onClick={() => setShowModal(false)}
              style={{
                position: 'absolute',
                top: '20px',
                right: '20px',
                color: '#286DB8',
                cursor: 'pointer',
              }}
              icon={faTimes}
            />

            <div className="thumb-produto">
              <img
                className="imagem-produto"
                src={produto.urlImagemMiniatura}
                alt=""
              />
            </div>
            <div
              className="descricao-produto"
              style={{ paddingTop: '10px', textAlign: 'center' }}
            >
              <h3 style={{ color: '#646464' }}>Informações</h3>

              <Table striped bordered hover>
                <tbody>
                  <tr>
                    <td style={{ color: '#646464', fontWeight: 'bold' }}>
                      Código
                    </td>
                    <td>{produto?.codigo}</td>
                  </tr>
                  <tr>
                    <td style={{ color: '#646464', fontWeight: 'bold' }}>
                      Descrição
                    </td>
                    <td>{produto?.descricao}</td>
                  </tr>
                  <tr>
                    <td style={{ color: '#646464', fontWeight: 'bold' }}>
                      Categoria Imagem
                    </td>
                    <td>{produto?.bancoImagemCategoria.nome}</td>
                  </tr>
                  <tr>
                    <td style={{ color: '#646464', fontWeight: 'bold' }}>
                      Categoria Produto
                    </td>
                    <td>{produto?.produtoCategoria?.nome}</td>
                  </tr>
                  <tr>
                    <td style={{ color: '#646464', fontWeight: 'bold' }}>
                      Produto
                    </td>
                    <td>--</td>
                  </tr>
                  <tr>
                    <td style={{ color: '#646464', fontWeight: 'bold' }}>
                      Situação
                    </td>
                    <td>{produto.ativo ? 'Ativo' : 'Inativo'}</td>
                  </tr>
                </tbody>
              </Table>
              <Button
                onClick={() => {
                  downloadImagem(produto);
                }}
                style={{
                  width: '340px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderRadius: '30px',
                }}
              >
                <FontAwesomeIcon className="icon" icon={faDownload} />
                <div style={{ width: '100%' }}>FAZER DOWNLOAD DA IMAGEM</div>
              </Button>
              <div
                style={{
                  width: '340px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: '15px',
                }}
              >
                {(progress.status === 'finished' ||
                  progress.status === 'in-progress') && (
                  <span
                    className="percentage"
                    style={{ padding: '1.5rem 0rem' }}
                  >
                    {progress.percentage ?? 0}% <br />
                    {progress.percentage === 100 && 'Download Concluído'}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CardItem;
