/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';

import TitlePages from 'components/_base/TitlePages';
import SubHeader from 'components/SubHeader';

import ResumoMaterial from '../Resumo/ResumoMaterial';

import ImgFluxo from 'assets/imgs/fluxo.png';
import './styles.scss';

const Sucesso = (props) => {
  let model = props.model;
  let pedidoCriado = props.pedidoCriado;
  let entities = props.entities;
  let pedidoTipo = props.pedidoTipo;

  return (
    <div className="wrapper-content-pedido-resumo">
      <SubHeader title={model.loja?.razaoSocial} />

      <TitlePages title="Solicitações criadas com sucesso" />

      <div className="content-pedido-resumo">
        <div className="row mb-4">
          <div className="col-lg-12">
            <h4 className="text-center mb-5">
              Suas solicitações foram enviadas com sucesso! <br />
              Agora elas entrarão no fluxo de aprovação da plataforma, conforme
              a seguir:
            </h4>
            <img className="d-block w-100" src={ImgFluxo} alt="fluxo" />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <Accordion>
              {(model.solicitacoes || []).map((solicitacao, index) => {
                return (
                  <ResumoMaterial
                    key={index}
                    model={model}
                    loja={model.loja}
                    entities={entities}
                    solicitacao={solicitacao}
                    index={index}
                    pedidoCriado={pedidoCriado}
                    pedidoTipo={pedidoTipo}
                    entregaDigital={model.entregaDigital}
                    entregaCentroDistribuicao={model.entregaCentroDistribuicao}
                    entregaLojasParticipantes={model.entregaLojasParticipantes}
                    entregaOutro={model.entregaOutro}
                  />
                );
              })}
            </Accordion>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 d-flex flex-row-reverse">
            <Link to="/">
              <button className="btn btn-primary btn-navigation btn-navigation-right">
                <span>Pagina Inicial</span>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sucesso;
