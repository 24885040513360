import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './themes/styles.scss';
import App from './App';

import storeConfig from './store/storeConfig';
import withClearCache from 'util/ClearCache';

const store = storeConfig();

const ClearCacheComponent = withClearCache(App);

ReactDOM.render(
  <Provider store={store}>
    <ClearCacheComponent />
  </Provider>,
  document.getElementById('root')
);
