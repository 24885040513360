/* eslint-disabled */
import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { GET_BANCO_IMAGEM_LIST_DOWNLOAD_TOKEN } from 'services/BancoImagem';
import { Context } from 'context/AuthContext';
import axios from 'axios';

import PageWithoutHeader from 'components/PageWithoutHeader';
import './styles.scss';

const DownloadWithToken = () => {
  const params = new URLSearchParams(useLocation().search);
  const [imagens, setImagens] = useState([]);
  const [errors, setErrors] = useState(null);
  const { showLoader } = useContext(Context);

  useEffect(() => {
    (async () => {
      showLoader(true);
      const data = await GET_BANCO_IMAGEM_LIST_DOWNLOAD_TOKEN(
        params.get('token')
      );

      if (data.errors && data.errors.length > 0) {
        setErrors(data.errors[0]);
      }

      setImagens(data.value);
      showLoader(false);
    })();
  }, []);

  const download = (img) => {
    const documentStyles = document.documentElement.style;
    let progress = 0;

    img.progress = 'in-progress';

    axios({
      url: img.urlImagem,
      method: 'GET',
      responseType: 'blob',
      onDownloadProgress(progressEvent) {
        progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );

        img.percentage = progress;

        documentStyles.setProperty('--progress', `${progress}%`);
        setImagens([...imagens]);
      },
    }).then((response) => {
      img.progress = 'finished';
      setImagens([...imagens]);

      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      let filename = img.urlImagem.substring(
        img.urlImagem.lastIndexOf('/') + 1
      );
      filename = filename.split('?')[0];
      link.setAttribute('download', filename); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  };

  const downloadAll = () => {
    imagens.forEach(function (img) {
      download(img);
    });
  };

  return (
    <PageWithoutHeader title="Baixar Mídias">
      {errors ? (
        <div className="alert alert-danger">{errors}</div>
      ) : (
        <div className="wrapper-banco-imagens-download">
          <div className="text-center">
            <button className="btn btn-primary" onClick={downloadAll}>
              Baixar Todas as Mídias
            </button>
          </div>
          <hr></hr>
          <br></br>
          {imagens && imagens != [] && (
            <table className="table">
              <tbody>
                {imagens.map((img, index) => (
                  <tr key={index}>
                    <td align="right">{img.descricao}</td>
                    <td align="left">
                      <div className={`progress-button ${img.progress}`}>
                        {img.progress === 'in-progress' ? (
                          <label>Baixando</label>
                        ) : (
                          <button
                            className="btn btn-primary"
                            onClick={() => download(img)}
                          >
                            Baixar
                          </button>
                        )}
                      </div>
                    </td>
                    <td>
                      {(img.progress === 'finished' ||
                        img.progress === 'in-progress') && (
                        <span className="percentage">
                          {img.percentage ?? 0}%
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}
    </PageWithoutHeader>
  );
};

export default DownloadWithToken;
