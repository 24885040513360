import api from 'api';

const PATH = '/BancoImagem';

const GET_BANCO_IMAGEM = async (id) => {
  const { data } = await api.get(`${PATH}/get-banco-imagem/${id}`);
  return data;
};

const GET_BANCO_IMAGEM_BY_IDS = async (ids) => {
  const { data } = await api.get(`${PATH}/get-banco-imagens-by-ids?ids=${ids}`);
  return data;
};

const GET_BANCO_IMAGEM_CATEGORIAS = async () => {
  const { data } = await api.get(`${PATH}/get-list-categoria-banco-imagem`);
  return data;
};

const GET_BANCO_IMAGEM_CONTEUDOS = async () => {
  const { data } = await api.get(`${PATH}/get-list-conteudo-banco-imagem`);
  return data;
};

const GET_ENVIAR_EMAIL = async (ids, email) => {
  const { data } = await api.get(
    `${PATH}/enviar-email?ids=${ids}&email=${email}`
  );
  return data;
};

const GET_LIST_BANCO_IMAGEM_FILTRO = async (filtros) => {
  const { data } = await api.get(
    `${PATH}/get-list-paged-banco-imagem-por-filtro`,
    filtros
  );
  return data;
};

const GET_BANCO_IMAGEM_LIST_DOWNLOAD_TOKEN = async (token) => {
  const { data } = await api.get(`${PATH}/get-imagens-download?token=${token}`);
  return data;
};

export {
  GET_BANCO_IMAGEM,
  GET_BANCO_IMAGEM_CATEGORIAS,
  GET_LIST_BANCO_IMAGEM_FILTRO,
  GET_BANCO_IMAGEM_CONTEUDOS,
  GET_BANCO_IMAGEM_LIST_DOWNLOAD_TOKEN,
  GET_ENVIAR_EMAIL,
  GET_BANCO_IMAGEM_BY_IDS,
};
