/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

import api from 'api';
import {
  GET_LIST_BANCO_IMAGEM_FILTRO,
  GET_ENVIAR_EMAIL,
  GET_BANCO_IMAGEM_BY_IDS,
} from 'services/BancoImagem';
import { GET_IMAGE_ZIP } from 'services/Download';
import { Context } from 'context/AuthContext';
import actions from 'store/modules/bancoImagens/actions';

import TitlePages from 'components/_base/TitlePages';
import Filter from 'components/Filter';
import SingleSelect from 'components/_base/SelectSingle';
import CardImage from 'components/CardImage';

import './styles.scss';

const qs = require('qs');

const ContentBancoImagens = () => {
  const { showLoader } = useContext(Context);
  const state = useSelector((state) => state.bancoImagens);
  const [page, setPage] = useState(1);
  const [dataCardImages, setDataCardImage] = useState({});
  const [filterParams, setFilterParams] = useState({
    CategoriaImagemIds: [],
    ConteudoImagemIds: [],
    MarcaIds: [],
    CategoriaProdutoIds: [],
    GrupoIds: [],
    Texto: '',
  });

  const [show, setShow] = useState(false);
  const [showDownload, setShowDownload] = useState(false);
  const [email, setEmail] = useState(false);
  const [listaDownload, setListaDownload] = useState([]);
  const [downloadReady, setDownloadReady] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleHideDownload = () => setShowDownload(false);
  const handleShowDownload = () => setShowDownload(true);

  const dispatch = useDispatch();
  const history = useHistory();
  let query = useQuery();

  const download = (img) => {
    const documentStyles = document.documentElement.style;
    let progress = 0;

    img.progress = 'in-progress';

    axios({
      url: img.urlImagem,
      method: 'GET',
      responseType: 'blob',
      onDownloadProgress(progressEvent) {
        progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );

        img.percentage = progress;

        documentStyles.setProperty('--progress', `${progress}%`);
        setListaDownload([...listaDownload]);
      },
    }).then((response) => {
      img.progress = 'finished';
      setListaDownload([...listaDownload]);

      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      let filename = img.urlImagem.substring(
        img.urlImagem.lastIndexOf('/') + 1
      );
      filename = filename.split('?')[0];
      link.setAttribute('download', filename); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  };

  const downloadAll = () => {
    listaDownload.forEach(function (img) {
      download(img);
    });
  };

  useEffect(() => {
    downloadAll();
  }, [downloadReady]);

  const downloadProdutosSelecionados = async () => {
    showLoader(true);
    const data = await GET_IMAGE_ZIP(state.produtosSelecionados);

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'imagens.zip');
    document.body.appendChild(link);
    link.click();
    showLoader(false);
  };

  const newDownload = async () => {
    showLoader(true);
    const { value } = await GET_BANCO_IMAGEM_BY_IDS(state.produtosSelecionados);
    setListaDownload(value);
    setDownloadReady(!downloadReady);
    showLoader(false);
  };

  const sendEmailDownload = async () => {
    showLoader(true);
    const sucess = await GET_ENVIAR_EMAIL(state.produtosSelecionados, email);
    showLoader(false);
    if (Boolean(sucess)) {
      setShow(false);
    }
  };

  async function changePage(page) {
    showLoader(true);
    setPage(page);
    const data = await GET_LIST_BANCO_IMAGEM_FILTRO({
      params: {
        PageNumber: page,
        PageSize: 20,
        ...filterParams,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    history.push(
      `?pagina=${page}${
        filterParams.Texto ? '&nome=' + filterParams.Texto : ''
      }${
        filterParams.CategoriaImagemIds.length !== 0
          ? '&categoria=' + filterParams.CategoriaImagemIds
          : ''
      }${
        filterParams.ConteudoImagemIds.length !== 0
          ? '&conteudo=' + filterParams.ConteudoImagemIds
          : ''
      }${
        filterParams.MarcaIds.length !== 0
          ? '&marca=' + filterParams.MarcaIds
          : ''
      }${
        filterParams.CategoriaProdutoIds.length !== 0
          ? '&categoriaProduto=' + filterParams.CategoriaProdutoIds
          : ''
      }${
        filterParams.GrupoIds.length !== 0
          ? '&grupo=' + filterParams.GrupoIds
          : ''
      }`
    );
    setDataCardImage(data.value);
    showLoader(false);
  }

  const selecionarTodas = () => {
    let items = state.produtosSelecionados;

    dataCardImages.data.forEach(function (imagem, i) {
      if (!items.includes(imagem.bancoImagemId)) {
        items.push(imagem.bancoImagemId);
      }
    });

    const action = actions.setProdutosSelecionados(items);
    dispatch(action);
    const action2 = actions.mostrarBotaoDownload(
      state.produtosSelecionados.length > 0 ? true : false
    );
    dispatch(action2);
  };

  const removerTodasSelecionadas = () => {
    const action = actions.setProdutosSelecionados([]);
    dispatch(action);
    const action2 = actions.mostrarBotaoDownload(false);
    dispatch(action2);
  };

  // todo
  // remover ou alterar para usar essa função
  const verificarTodasSelecionadas = () => {
    let items = state.produtosSelecionados;

    let todasSelecionadas = true;

    if (
      dataCardImages &&
      dataCardImages.data &&
      dataCardImages.data.length > 0
    ) {
      dataCardImages.data.forEach(function (imagem, i) {
        if (!items.includes(imagem.bancoImagemId)) {
          todasSelecionadas = false;
          return;
        }
      });
    }

    return todasSelecionadas;
  };

  const filterImages = async (
    texto,
    marcasSelecionadas,
    categoriasProdutosSelecionados,
    gruposSelecionados,
    categoriasImagensSelecionadas,
    conteudosImagensSelecionadas
  ) => {
    setFilterParams({
      Texto: texto,
      CategoriaImagemIds: categoriasImagensSelecionadas,
      ConteudoImagemIds: conteudosImagensSelecionadas,
      MarcaIds: marcasSelecionadas,
      CategoriaProdutoIds: categoriasProdutosSelecionados,
      GrupoIds: gruposSelecionados,
    });

    let params = {
      PageNumber: 1,
      PageSize: 20,
      CategoriaImagemIds: categoriasImagensSelecionadas,
      ConteudoImagemIds: conteudosImagensSelecionadas,
      MarcaIds: marcasSelecionadas,
      CategoriaProdutoIds: categoriasProdutosSelecionados,
      GrupoIds: gruposSelecionados,
      Texto: texto,
    };
    showLoader(true);

    history.push(
      `?pagina=1${texto ? '&nome=' + texto : ''}${
        categoriasImagensSelecionadas.length !== 0
          ? '&categoria=' + categoriasImagensSelecionadas
          : ''
      }${
        conteudosImagensSelecionadas.length !== 0
          ? '&conteudo=' + conteudosImagensSelecionadas
          : ''
      }${marcasSelecionadas !== 0 ? '&marca=' + marcasSelecionadas : ''}${
        categoriasProdutosSelecionados.length !== 0
          ? '&categoriaProduto=' + categoriasProdutosSelecionados
          : ''
      }${gruposSelecionados.length !== 0 ? '&grupo=' + gruposSelecionados : ''}`
    );

    const data = await GET_LIST_BANCO_IMAGEM_FILTRO({
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });

    setDataCardImage(data.value);
    showLoader(false);
  };

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  useEffect(() => {
    (async () => {
      showLoader(true);

      let paginaAtual = query.get('pagina')
        ? parseInt(query.get('pagina'))
        : page;
      let nomeAtual = query.get('nome')
        ? query.get('nome')
        : filterParams.Texto;
      let categoriaAtual = query.get('categoria')
        ? query.get('categoria').split(',').map(Number)
        : filterParams.CategoriaImagemIds;
      let conteudoAtual = query.get('conteudo')
        ? query.get('conteudo').split(',').map(Number)
        : filterParams.ConteudoImagemIds;
      let marcaAtual = query.get('marca')
        ? query.get('marca').split(',').map(Number)
        : filterParams.MarcaIds;
      let categoriaProdutoAtual = query.get('categoriaProduto')
        ? query.get('categoriaProduto').split(',')
        : filterParams.CategoriaProdutoIds;
      let grupoAtual = query.get('grupo')
        ? query.get('grupo').split(',')
        : filterParams.GrupoIds;

      let params = {
        PageNumber: paginaAtual,
        PageSize: 20,
        CategoriaImagemIds: categoriaAtual,
        ConteudoImagemIds: conteudoAtual,
        MarcaIds: marcaAtual,
        CategoriaProdutoIds: categoriaProdutoAtual,
        GrupoIds: grupoAtual,
        Texto: nomeAtual,
      };
      const data = await GET_LIST_BANCO_IMAGEM_FILTRO({
        params: params,
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      });
      setFilterParams({
        ...filterParams,
        CategoriaImagemIds: categoriaAtual.length
          ? categoriaAtual
          : filterParams.CategoriaImagemIds,
        ConteudoImagemIds: conteudoAtual.length
          ? conteudoAtual
          : filterParams.ConteudoImagemIds,
        MarcaIds: marcaAtual.length ? marcaAtual : filterParams.MarcaIds,
        CategoriaProdutoIds: categoriaProdutoAtual.length
          ? categoriaProdutoAtual
          : filterParams.CategoriaProdutoIds,
        GrupoIds: grupoAtual.length ? grupoAtual : filterParams.GrupoIds,
        Texto: nomeAtual ? nomeAtual : filterParams.Texto,
      });
      setPage(paginaAtual ? parseInt(paginaAtual) : page);
      setDataCardImage(data.value);
      showLoader(false);
    })();
  }, []);

  return (
    <div className="wrapper-banco-imagens">
      <TitlePages title="Banco de Mídias" />
      <div className="content">
        <div className="column1 col-lg-3">
          <Filter filtrar={filterImages} />
        </div>
        <div
          className="column2 float-left col-lg-9"
          style={{ position: 'static' }}
        >
          <div
            className="order"
            style={{
              justifyContent: state.mostrarBotaoDownload
                ? 'space-between'
                : 'flex-end',
            }}
          >
            <div
              className="download"
              style={{ display: state.mostrarBotaoDownload ? 'flex' : 'none' }}
            >
              <Button
                style={{
                  maxWidth: '340px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                // onClick={downloadProdutosSelecionados}
                onClick={() => {
                  handleShowDownload();
                  newDownload();
                }}
              >
                <FontAwesomeIcon className="icon" icon={faDownload} />
                <div style={{ width: '100%' }}>BAIXAR SELECIONADAS</div>
                <div className="total">{state.produtosSelecionados.length}</div>
              </Button>
            </div>

            <div
              className="download"
              style={{ display: state.mostrarBotaoDownload ? 'flex' : 'none' }}
            >
              <Button
                style={{
                  maxWidth: '340px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                onClick={handleShow}
              >
                <FontAwesomeIcon className="icon" icon={faPaperPlane} />
                <div style={{ width: '100%' }}>ENVIAR PARA O E-MAIL</div>
                <div className="total">{state.produtosSelecionados.length}</div>
              </Button>
            </div>

            <div className="todas">
              <Button onClick={selecionarTodas}>SELECIONAR TODAS</Button>
            </div>
            <div className="ml-3 todas">
              <Button
                disabled={!state.produtosSelecionados.length}
                onClick={removerTodasSelecionadas}
              >
                EXCLUIR TODAS SELEÇÕES
              </Button>
            </div>
          </div>
          <div className="cards">
            <div
              className=" col-lg-12 cards-align "
              style={{ position: 'static' }}
            >
              <CardImage
                {...dataCardImages}
                changePage={changePage}
                produtosSelecionados={state.produtosSelecionados}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Insira o e-mail para receber os links de download.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label>E-mail</label>
            <input
              type="text"
              className="form-control"
              value={email || ''}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={sendEmailDownload}>
            ENVIAR
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDownload} onHide={handleHideDownload}>
        <Modal.Header closeButton>
          <Modal.Title>Downloads</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {listaDownload && listaDownload !== [] && (
              <table className="table">
                <tbody>
                  {listaDownload.map((img) => (
                    <tr key={img.bancoImagemId}>
                      <td align="right">{img.descricao}</td>
                      <td>
                        {(img.progress === 'finished' ||
                          img.progress === 'in-progress') && (
                          <span className="percentage">
                            {img.percentage ?? 0}%
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ContentBancoImagens;
