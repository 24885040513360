import React, { useState } from 'react';
import { geoCentroid } from 'd3-geo';
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Annotation,
  ZoomableGroup,
} from 'react-simple-maps';

import brTopoJson from '../../data/br-topo.json';
import SemDados from '../SemDados';

const statesWithAnnotations = {
  BR_DF: {
    annotation: { x: -10, y: -15 },
    tag: { fontSize: 22, x: 4, y: 0 },
  },
  BR_RN: {
    annotation: { x: 28, y: 0 },
    tag: { fontSize: 22, x: 4, y: 0 },
  },
  BR_PB: {
    annotation: { x: 32, y: 0 },
    tag: { fontSize: 22, x: 4, y: 0 },
  },
  BR_PE: {
    annotation: { x: 50, y: 0 },
    tag: { fontSize: 22, x: 4, y: 0 },
  },
  BR_AL: {
    annotation: { x: 30, y: 0 },
    tag: { fontSize: 22, x: 4, y: 0 },
  },
  BR_SE: {
    annotation: { x: 25, y: 0 },
    tag: { fontSize: 22, x: 4, y: 0 },
  },
  BR_ES: {
    annotation: { x: 20, y: 0 },
    tag: { fontSize: 22, x: 4, y: 0 },
  },
  BR_RJ: {
    annotation: { x: 25, y: 0 },
    tag: { fontSize: 22, x: 4, y: 0 },
  },
};

const geographyStyle = {
  backgroundColor: 'white',
  stroke: '#404345',
  strokeWidth: 1,
  outline: 'none',
  cursor: 'pointer',
  transition: 'all .2s',
};

const UFSolicitacao = (props) => {
  const { data } = props;

  const titulo = 'SOLICITAÇÕES POR UF';

  const [selectedState, setSelectedState] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [position, setPosition] = useState({
    coordinates: [-54, -14],
    zoom: 1,
  });

  if (!data || data.length < 1) return <SemDados titulo={titulo} />;

  const itens = data.map((item) => {
    return item.nome;
  });

  const itensPorcentagem = data.map((item) => {
    return item.porcentagem;
  });

  const itensQtd = data.map((item) => {
    return item.total;
  });

  const handleMouseMove = (event) => {
    setMousePosition({ x: event.clientX, y: event.clientY });
  };
  const handleMouseEnter = (geoId, event) => {
    setSelectedState(geoId);
    setMousePosition({ x: event.clientX, y: event.clientY });
  };
  const handleMouseLeave = () => {
    setSelectedState(null);
  };
  function handleZoomIn() {
    if (position.zoom >= 4) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom * 2 }));
  }
  function handleZoomOut() {
    if (position.zoom <= 1) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom / 2 }));
  }

  function handleMoveEnd(pos) {
    setPosition(pos);
  }

  const renderGeograph = (dataSource, countryId, countryColor) => {
    return (
      <Geographies geography={dataSource} onMouseMove={handleMouseMove}>
        {({ geographies }) => (
          <>
            {geographies.map((geo) => {
              let stateColor;
              // convert to double
              let porcentagem;
              if (itens.indexOf(geo.properties.id) > -1) {
                porcentagem =
                  itensPorcentagem[itens.indexOf(geo.properties.id)];
              }
              if (porcentagem && porcentagem > 50) {
                stateColor = '#30ca66';
              } else if (porcentagem > 25) {
                stateColor = '#7fce9a';
              } else if (porcentagem > 12.5) {
                stateColor = '#4990c0';
              } else if (porcentagem > 0) {
                stateColor = '#89afc9';
              } else {
                stateColor = countryColor;
              }
              return (
                <Geography
                  key={`${geo.rsmKey}-Geography`}
                  geography={geo}
                  onMouseEnter={(event) =>
                    handleMouseEnter(geo.properties.id.toLowerCase(), event)
                  }
                  onMouseLeave={handleMouseLeave}
                  style={{
                    default: {
                      ...geographyStyle,
                      fill: stateColor,
                    },
                    hover: {
                      ...geographyStyle,
                      stroke: '#111111',
                      strokeWidth: 3,
                      fill: stateColor,
                      filter: 'brightness(80%)',
                    },
                    pressed: {
                      ...geographyStyle,
                    },
                  }}
                />
              );
            })}

            {geographies.map((geo) => {
              const centroid = geoCentroid(geo);
              const geoId = geo.properties.id.toLowerCase();
              const annotationOffset =
                statesWithAnnotations[`${countryId}_${geoId}`];
              const tagPosition =
                annotationOffset && annotationOffset.tag
                  ? annotationOffset.tag
                  : {
                      x: 2,
                      y: 0,
                      fontSize: 22,
                    };

              return (
                <g
                  key={`${geo.rsmKey}-Marker`}
                  style={{ pointerEvents: 'none', color: '#fff' }}
                >
                  {annotationOffset ? (
                    <Annotation
                      connectorProps={{
                        stroke: 'rgb(239,171,11)',
                      }}
                      subject={centroid}
                      dx={annotationOffset.annotation.x}
                      dy={annotationOffset.annotation.y}
                    >
                      <text
                        x={tagPosition.x}
                        y={tagPosition.y}
                        fontSize={tagPosition.fontSize}
                        alignmentBaseline="middle"
                      >
                        {/* {geoId}- */}
                        {itens.indexOf(geoId.toUpperCase()) > -1
                          ? `${Math.round(
                              itensPorcentagem[
                                itens.indexOf(geoId.toUpperCase())
                              ]
                            )}%`
                          : ''}
                      </text>
                    </Annotation>
                  ) : (
                    <Marker coordinates={centroid}>
                      <text
                        x={tagPosition.x}
                        y={tagPosition.y}
                        fontSize={tagPosition.fontSize}
                        textAnchor="middle"
                      >
                        {/* {geoId}- */}
                        {itens.indexOf(geoId.toUpperCase()) > -1
                          ? `${Math.round(
                              itensPorcentagem[
                                itens.indexOf(geoId.toUpperCase())
                              ]
                            )}%`
                          : ''}
                      </text>
                    </Marker>
                  )}
                </g>
              );
            })}
          </>
        )}
      </Geographies>
    );
  };

  return (
    <div className="grafico mapa">
      <div className="grafico-titulo">
        <span>{titulo}</span>
      </div>
      <div className="mapa-container" style={{ height: 250, width: 300 }}>
        <div className="zoom-buttons">
          <button
            type="button"
            style={{ marginBottom: '5px', border: '1px solid' }}
            onClick={handleZoomIn}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <line x1="12" y1="5" x2="12" y2="19" />
              <line x1="5" y1="12" x2="19" y2="12" />
            </svg>
          </button>
          <button
            type="button"
            style={{ marginBottom: '5px', border: '1px solid' }}
            onClick={handleZoomOut}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <line x1="5" y1="12" x2="19" y2="12" />
            </svg>
          </button>
        </div>
        <ComposableMap
          projection="geoMercator"
          projectionConfig={{
            scale: 650,
            center: [-54, -14],
          }}
          width={500}
          height={500}
        >
          <ZoomableGroup
            zoom={position.zoom}
            center={position.coordinates}
            onMoveEnd={handleMoveEnd}
          >
            {renderGeograph(brTopoJson, 'BR', '#e5e5e5')}
          </ZoomableGroup>
        </ComposableMap>

        {selectedState && (
          <div
            className="tooltip-mapa"
            style={{
              position: 'fixed',
              top: mousePosition.y,
              left: mousePosition.x,
            }}
          >
            <span>{selectedState.toUpperCase()}</span>
            <br />
            <span>
              Porcentagem:
              {itensPorcentagem[itens.indexOf(selectedState.toUpperCase())] ||
                '0,00'}
              %
            </span>
            <br />
            <span>
              Total de Solicitações:
              {itensQtd[itens.indexOf(selectedState.toUpperCase())] || '0'}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default UFSolicitacao;
