/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react';
import { Carousel } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';

import HeaderApp from 'components/Header/HeaderApp';
import Bottom from 'components/Bottom';
import { Context } from 'context/AuthContext';
import { GET_LIST_AVISO } from 'services/Aviso';
import { COUNT_PENDENCIA_USUARIO_ATUAL } from 'services/Pendencia';
import { COUNT_RASCUNHO_USUARIO_ATUAL } from 'services/Rascunho';

import rascunho from 'assets/imgs/icons/rascunho.png';
import pendencias from 'assets/imgs/icons/pendencias.png';
import onoff from 'assets/imgs/icons/plano_midias_on_off.png';
import artesProntas from 'assets/imgs/icons/icon_artesprontas.svg';
import relatorios from 'assets/imgs/icons/relatorios.png';
import materiais from 'assets/imgs/icons/icon_materiais.svg';
import histpedidos from 'assets/imgs/icons/icon_histpedidos.svg';
import consulta from 'assets/imgs/icons/consultar.png';

import './styles.scss';

const ContentHome = () => {
  const [warnings, setWarnings] = useState([]);
  const { showLoader } = useContext(Context);
  const [corPendencia, setCorPendencia] = useState([]);
  const [corRascunho, setCorRascunho] = useState([]);
  const loginUserData = JSON.parse(localStorage.getItem('midias_farmax'));

  useEffect(() => {
    async function fetchData() {
      showLoader(true);

      const corPendenciaResult = await COUNT_PENDENCIA_USUARIO_ATUAL();
      setCorPendencia(corPendenciaResult.value);

      const countUsuarioAtual = await COUNT_RASCUNHO_USUARIO_ATUAL();
      setCorRascunho(countUsuarioAtual.value);

      const warningsResult = await GET_LIST_AVISO();
      setWarnings(warningsResult.value);

      showLoader(false);
    }
    fetchData();
  }, []);

  if (loginUserData && loginUserData.acessaApenasBancoMidias === true) {
    return <Redirect to="/banco-de-imagens" />;
  }

  return (
    <>
      <div className="wrapper-choose-the-costumer">
        <div className="warnings">
          <Carousel>
            {warnings?.map((item) => {
              return (
                <Carousel.Item key={item.avisoId}>
                  {item.urlRedirecionamento ? (
                    <a
                      href={item.urlRedirecionamento}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="d-block w-100"
                        src={item.urlImagem}
                        alt={item.texto}
                      />
                    </a>
                  ) : (
                    <img
                      className="d-block w-100"
                      src={item.urlImagem}
                      alt={item.texto}
                    />
                  )}
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
        {/* <div className="actions"> */}
        <div className="actions">
          {(loginUserData && loginUserData.acessaApenasBancoMidias) === true ? (
            <div>
              <Link to="/banco-de-imagens">
                <div>
                  <div className="button-content">
                    <div>
                      <img src={onoff} alt="Consultar" />
                    </div>
                    <div className="btn-divider" />
                    <div>Banco de Mídias</div>
                  </div>
                  <div className="button-content-hover">
                    <p>Banco de Mídias para download (mockups).</p>
                  </div>
                </div>
              </Link>
            </div>
          ) : (
            <>
              <div>
                {/* <Link to="/artes-prontas">
                  <div>
                    <div className="button-content">
                      <div>
                        <img src={artesProntas} alt="Artes fácil" />
                      </div>
                      <div className="btn-divider" />
                      <div>Artes prontas</div>
                    </div>
                    <div className="button-content-hover">
                      <p>
                        Artes pré aprovadas para troca apenas de logo, preço e
                        ação promocional.
                      </p>
                    </div>
                  </div>
                </Link> */}
                <Link to="/pedido-materiais">
                  <div>
                    <div className="button-content">
                      <div>
                        <img src={materiais} alt="pedido-materiais" />
                      </div>
                      <div className="btn-divider" />
                      <div>Materiais Personalizados</div>
                    </div>
                    <div className="button-content-hover">
                      <p>
                        Solicite artes personalizadas com qualquer layout,
                        produto ou dimensões necessárias.
                      </p>
                    </div>
                  </div>
                </Link>
                <Link to="/relatorios">
                  <div>
                    <div className="button-content">
                      <div>
                        <img src={relatorios} alt="Relatorios" />
                      </div>
                      <div className="btn-divider" />
                      <div>Relatórios</div>
                    </div>
                    <div className="button-content-hover">
                      <p>
                        Relatórios de pedidos feitos na plataforma, extratos mensais e parcial.
                      </p>
                    </div>
                  </div>
                </Link>
              </div>

              <div>
                <Link to="/consulta">
                  <div>
                    <div className="button-content">
                      <div>
                        <img src={consulta} alt="consulta" />
                      </div>
                      <div className="btn-divider" />
                      <div>Consulta</div>
                    </div>
                    <div className="button-content-hover">
                      <p>Consulte as solicitações já realizadas</p>
                    </div>
                  </div>
                </Link>

                <Link to="/historico-pedidos">
                  <div>
                    <div className="button-content">
                      <div>
                        <img src={histpedidos} alt="Historico pedido" />
                      </div>
                      <div className="btn-divider" />
                      <div>Histórico de pedidos</div>
                    </div>
                    <div className="button-content-hover">
                      <p>
                        Histórico de seus pedidos feitos caso queira
                        reaproveitá-los para solicitar novamente
                      </p>
                    </div>
                  </div>
                </Link>
              </div>

              <div>
                <Link to="/pendencias">
                  <div
                    style={{ backgroundColor: corPendencia > 0 ? 'red' : '' }}
                  >
                    <div className="button-content">
                      <div>
                        <img src={pendencias} alt="pendencias" />
                      </div>
                      <div className="btn-divider" />
                      <div>Pendências</div>
                    </div>
                    <div className="button-content-hover">
                      <p>
                        Solicitações pendentes de alguma ação (aprovação /
                        reprovação)
                      </p>
                    </div>
                  </div>
                </Link>
                <Link to="/rascunho">
                  <div
                    style={{ backgroundColor: corRascunho > 0 ? 'red' : '' }}
                  >
                    <div className="button-content">
                      <div>
                        <img src={rascunho} alt="Rascunhos" />
                      </div>
                      <div
                        className="btn-divider"
                        style={{ marginLeft: '10px' }}
                      />
                      <div>Rascunhos</div>
                    </div>
                    <div className="button-content-hover">
                      <p>Rascunhos de solicitações não enviadas.</p>
                    </div>
                  </div>
                </Link>
              </div>

              <div>
                <Link to="/banco-de-imagens">
                  <div>
                    <div className="button-content">
                      <div>
                        <img src={onoff} alt="Consultar" />
                      </div>
                      <div className="btn-divider" />
                      <div>Banco de Mídias</div>
                    </div>
                    <div className="button-content-hover">
                      <p>Banco de Mídias para download (mockups).</p>
                    </div>
                  </div>
                </Link>
                <Link to="/banco-de-artes">
                  <div>
                    <div className="button-content">
                      <div>
                        <img src={rascunho} alt="Consultar" />
                      </div>
                      <div className="btn-divider" />
                      <div>Banco de artes</div>
                    </div>
                    <div className="button-content-hover">
                      <p>Banco de Artes já feitas na plataforma.</p>
                    </div>
                  </div>
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-center text-center mb-3">
        <h5 className="text-primary font-weight-bold">
          EM CASO DE DÚVIDAS OU OCORRÊNCIAS SOBRE O MAIS QUE IMAGENS FARMAX,
          LIGAR PARA (11) 94519-0299 DE SEGUNDA A SEXTA NO HORÁRIO DAS 9:00{' '}
          <br /> ÀS 18:00 OU, SE PREFERIR, CLIQUE EM ATENDIMENTO AO USUÁRIO E
          ABRA UM CHAMADO QUE ENTRAREMOS EM CONTATO.
        </h5>
      </div>
    </>
  );
};

const App = () => {
  return (
    <>
      <HeaderApp />
      <ContentHome />
      <Bottom />
    </>
  );
};

export default App;
