import React from 'react';

import Header from 'components/Header/HeaderApp';
import Bottom from 'components/Bottom';

const BancoDeMidias = () => {
  const userAuth = JSON.parse(localStorage.getItem('midias_farmax'));
  return (
    <>
      <Header />
      <a
        href={`https://bancodemidiashom.midiafarmax.com.br/login/${userAuth?.token_BancoDeMidias || ''
          }`}
      >
        <div>
          <div>Banco de Mídias</div>
        </div>
      </a>
      <Bottom />
    </>
  );
};

export default BancoDeMidias;
